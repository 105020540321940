import { registerApplication, start } from 'single-spa'
import { constructApplications, constructLayoutEngine } from 'single-spa-layout'
import '@grupoboticario/flora-screenrecording-addon'

import { type UserData, type IAuthService } from '@vdi/auth-service'
import { GtmTracker } from '@vdi/event-tracker'

import { factoryUserRoutes } from './utils/routes/factoryUserRoutes'
import { initializeServices } from './initializeServices'
import { monitoringClient } from './utils/monitoring'

function returnUserTypeByEmail(userData: UserData) {
  const domainFromUserEmail = userData.email.split('@')[1]

  if (
    domainFromUserEmail === 'grupoboticario.com.br' ||
    domainFromUserEmail === 'eudora.com.br' ||
    domainFromUserEmail === 'dbm.com.br' ||
    domainFromUserEmail === 'elo.com.br'
  ) {
    return 'internal'
  }

  return 'external'
}

function bootstrapUtilities(authService: IAuthService) {
  const userGeraId = authService.userData.geraId
  const userId = authService.userData.id
  const userType = returnUserTypeByEmail(authService.userData)

  const userInfoAnalytics = {
    event: 'VDIUser',
    user: {
      id: userId,
      geraId: userGeraId,
      userType
    }
  }

  GtmTracker.init(userGeraId)
  GtmTracker.track({ user_id: userId })
  GtmTracker.track(userInfoAnalytics)
}

function initRootApp(authService: IAuthService) {
  const layoutData = {
    loaders: {},
    props: {
      user: authService.userData
    }
  }
  const routes = factoryUserRoutes({ layoutData })

  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name)
    }
  })
  const layoutEngine = constructLayoutEngine({ routes, applications })

  applications.forEach(registerApplication)
  layoutEngine.activate()
}

initializeServices(bootstrapUtilities, initRootApp)
  .then(() => {
    start({
      urlRerouteOnly: true
    })
    return null
  })
  .catch((error) => {
    monitoringClient.error('Error initializing services', { error })
    console.error('Error initializing services')
  })
