import { type UserFeatures } from '@vdi/auth-service'
import { authorizationHelper } from '../../initializeServices'
import { renderAppWithRoute } from '../renderApp'

const journeyName = 'ca'

function renderDashboardAppWithRouteForFeature({ path, featureNames }: { path: string; featureNames: UserFeatures[] }) {
  const canRender =
    featureNames.some((featureName) => {
      return authorizationHelper.canAccessFeature({ featureName, journeyName })
    }) ||
    authorizationHelper.canAccessFeature({
      featureName: 'gr-dashboards-full',
      journeyName
    })

  return renderAppWithRoute({
    appName: '@vdi/gr-dashboards',
    path,
    canRender,
    props: 'user'
  })
}

function redirectFromPathsTo(paths, to) {
  return paths
    .map((path) => {
      return `<redirect from="${path}" to="${to}"></redirect>`
    })
    .join('')
}

function entryCaRoute() {
  switch (true) {
    case authorizationHelper.canAccessFeature({ featureName: 'gr-dashboards-overview', journeyName }):
    case authorizationHelper.canAccessFeature({
      featureName: 'gr-dashboards-full',
      journeyName
    }):
      return redirectFromPathsTo(['/', '/dashboards'], '/dashboards/visao-geral')

    case authorizationHelper.canAccessFeature({ featureName: 'gr-dashboards-attendance', journeyName }):
      return redirectFromPathsTo(['/', '/dashboards'], '/dashboards/atendimento')

    case authorizationHelper.canAccessFeature({ featureName: 'gr-dashboards-base-profile', journeyName }):
      return redirectFromPathsTo(['/', '/dashboards'], '/dashboards/perfil-de-base')

    case authorizationHelper.canAccessFeature({ featureName: 'gr-dashboards-activity', journeyName }):
      return redirectFromPathsTo(['/', '/dashboards'], '/dashboards/atividade')

    case authorizationHelper.canAccessFeature({ featureName: 'read-all-res', journeyName }):
      return redirectFromPathsTo(['/', '/ativacao'], '/ativacao/atendimento')

    default:
      return ''
  }
}

export function renderCaRoutes() {
  return `
    ${entryCaRoute()}

    ${renderDashboardAppWithRouteForFeature({ path: '/dashboards', featureNames: ['gr-dashboards-overview'] })}
    ${renderDashboardAppWithRouteForFeature({ path: '/dashboards/visao-geral', featureNames: ['gr-dashboards-overview'] })}
    ${renderDashboardAppWithRouteForFeature({ path: '/dashboards/atendimento', featureNames: ['gr-dashboards-attendance'] })}
    ${renderDashboardAppWithRouteForFeature({ path: '/dashboards/perfil-de-base', featureNames: ['gr-dashboards-base-profile'] })}
    ${renderDashboardAppWithRouteForFeature({ path: '/dashboards/atividade', featureNames: ['gr-dashboards-activity'] })}
    ${renderDashboardAppWithRouteForFeature({ path: '/dashboards/memoria-de-calculo', featureNames: ['gr-dashboards-overview', 'gr-dashboards-attendance'] })}

    ${renderAppWithRoute({
      appName: '@vdi/attendance',
      path: '/ativacao/atendimento',
      canRender: authorizationHelper.canAccessFeature({
        featureName: 'read-all-res',
        journeyName
      })
    })}

    ${renderAppWithRoute({
      appName: '@vdi/segmentation',
      path: '/ativacao/segmentacao',
      canRender: authorizationHelper.canAccessFeature({
        featureName: 'can-view-segmentation',
        journeyName
      })
    })}

    ${renderAppWithRoute({
      appName: '@vdi/users',
      path: '/admin/usuarios/criar',
      canRender: authorizationHelper.canAccessFeature({
        featureName: 'view-admin',
        journeyName
      })
    })}
  `
}
